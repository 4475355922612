import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/es/symbol';
import 'core-js/es/array/find';
import 'core-js/es/array/includes';
import 'es6-shim';

import 'whatwg-fetch';

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
