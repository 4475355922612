import { AuthenticationContext, adalGetToken } from 'react-adal';

const adalConfig = {
  tenant: process.env.REACT_APP_ADAL_TENANT_ID,
  clientId: process.env.REACT_APP_ADAL_CLIENT_ID,
  endpoints: {
    api: process.env.REACT_APP_ADAL_CLIENT_ID,
  },
  cacheLocation: 'localStorage',
  redirectUri: window.location.origin,
};

const { authToken: ownAuthToken, powerBIToken: ownPowerBIToken } = getQueryParams();

const isOwnAuth = Boolean(ownAuthToken && ownPowerBIToken);

const authContext = isOwnAuth ? getOwnAuthContext() : new AuthenticationContext(adalConfig);

export { adalConfig, authContext, isOwnAuth, ownAuthToken };

function getOwnAuthContext() {
  return {
    acquireToken: (uri, callback) => {
      if (uri === process.env.REACT_APP_POWER_BI_API) {
        callback(null, ownPowerBIToken);
      }
    },
    logOut: () => {},
  };
}

function getQueryParams() {
  let qs = window.location.search;

  if (!qs) return {};

  qs = qs.split('+').join(' ');
  const params = {};
  let tokens;
  const re = /[?&]?([^=]+)=([^&]*)/g;

  // eslint-disable-next-line no-cond-assign
  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

export function getAdalToken() {
  return adalGetToken(authContext, adalConfig.endpoints.api);
}
