import './polyfills';
import { runWithAdal } from 'react-adal';
import { isOwnAuth, authContext } from 'Common/Auth/adalConfig';

const DO_NOT_LOGIN = false;

if (isOwnAuth) {
  import('./indexApp');
} else {
  runWithAdal(
    authContext,
    () => {
      import('./indexApp');
    },
    DO_NOT_LOGIN
  );
}
